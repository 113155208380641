<template>
  <div>
    <div class="app-top">
      <swiper-list :dataList="swiperList" :height="500" :interval="4000">
        <template slot="0">
          <save-dialog>
            <el-button type="primary" style="width: 200px;">提交反馈</el-button>
          </save-dialog>
        </template>
      </swiper-list>
    </div>
    <div class="container">
      <!-- 暂时隐藏 -->
      <!-- <div style="margin-top: 20px; display: flex; justify-content: space-between; align-items: center; ">
        <div style="font-size: 14px; color: #999;">感谢您的反馈!</div>
        <product-select
          style="width: 200px;"
          :text="productName"
          placeholder="按软件查看"
          @change="product = $event.licenseCode,productName = $event.name,getData()"
        />
      </div>-->
      <div v-loading="listLoading">
        <div class="list">
          <div class="item" v-for="(item,index) in dataList" :key="index">
            <div class="left">
              <a :href="`http://${item.softLicenseCode}.jiandanke.cn`" target="_blank">
                <img class="soft-icon" :src="utils.getSoftIcon(item.softLicenseCode)" alt />
              </a>
              <div class="soft-name">{{item.softName}}</div>
              <div class="time">{{item.createTime}}</div>
            </div>
            <div class="right">
              <div class="user-info">
                <div class="user-name">{{item.name}}</div>
                <div class="user-address">来自 {{item.address}} {{item.ip}}</div>
              </div>
              <div class="content">{{item.content}}</div>
              <div class="replay">回复：{{item.reply||'暂无回复'}}</div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div style="text-align: center;">
          <pagination
            v-show="total.count>0"
            :total="Number(total.count)"
            :page.sync="pageIndex"
            :limit.sync="pageSize"
            @pagination="getList"
          />
        </div>
        <div style="width: 100%;  text-align: center; margin:20px 0">
          <save-dialog>
            <el-button type="primary" style="width: 200px;">提交反馈</el-button>
          </save-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/guestbook'
import Pagination from '@/components/Pagination'
import swiperList from '@/components/swiperList'
import saveDialog from './components/saveDialog.vue'
// import productSelect from './components/productSelect.vue'
export default {
  name: 'Guestbook',
  components: { swiperList, saveDialog, Pagination }, //productSelect
  data() {
    return {
      dataList: [],
      total: {},
      listLoading: true,
      swiperList: [
        {
          image: '',
          title: '在线反馈',
          subTitle: '您的宝贵建议是我们前进的动力。',
          backgroundColor: 'linear-gradient(rgba(100,100,100,0.5), rgba(100,100,100,0.5))',
          backgroundImage: require('@/assets/images/bg/bg-8.jpg')
        }
      ],
      pageIndex: 1,
      pageSize: 10,
      productName: '',
      product: ''
    }
  },
  watch: {},
  mounted() {},
  created() {
    this.getList()
    this.getTotal()
  },
  methods: {
    getData() {
      this.pageIndex = 1
      this.getList()
    },
    getList() {
      this.listLoading = true
      API.getList({ pageIndex: this.pageIndex, pageSize: this.pageSize, product: this.product })
        .then(res => {
          this.dataList = res.data
          this.listLoading = false
        })
        .catch(err => {
          setTimeout(() => {
            this.$nextTick(() => {
              this.listLoading = false
            })
          }, 100)
          console.log('err:' + err)
        })
    },
    getTotal() {
      API.getTotal({ pageIndex: this.pageIndex, pageSize: this.pageSize }).then(res => {
        this.total = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// 手机版
@media screen and (max-width: 576px) {
  .list {
    .item {
      padding: 10px !important;
      .left {
        width: 70px !important;
        .soft-icon {
          width: 30px !important;
          height: 30px !important;
        }
        .soft-name {
          display: none !important;
        }
      }
    }
  }
}

// 电脑端
.app-top {
  margin-top: 58px;
}
.list {
  .item {
    display: flex;
    padding: 20px;
    margin: 15px 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 12px -4px rgb(51 51 51 / 18%);
    .left {
      width: 150px;
      margin-right: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .soft-icon {
        width: 60px;
        height: 60px;
      }
      .soft-name {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
      }
      .time {
        font-size: 12px;
        color: #999;
        margin-top: 2px;
      }
    }
    .right {
      flex: 1;
      .user-info {
        display: flex;
        align-items: center;
        .user-name {
          color: #666;
          font-size: 14px;
        }
        .user-address {
          flex: 1;
          text-align: right;
          font-size: 12px;
          color: #999;
        }
      }
      .content {
        font-size: 14px;
        padding: 10px 0;
      }
      .replay {
        font-size: 14px;
        color: #f18444;
      }
    }
  }
}
</style>