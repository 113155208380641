<template>
  <div>
    <div @click="dialogVisible = true">
      <slot></slot>
    </div>
    <div v-if="dialogVisible">
      <el-dialog
        title="提交留言"
        append-to-body
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        @close="dialogVisible = false"
        :width="isPc ? '600px' : '300px'"
      >
        <el-form ref="form" :model="form" :rules="rules" :label-position=" isPc ? 'right' :'top'" label-width="100px" size="normal">
          <el-form-item prop="name" label="您的称呼">
            <el-input type="text" v-model="form.name" placeholder="您的称呼" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="联系电话">
            <el-input v-model="form.phone" placeholder="联系电话" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item prop="product" label="反馈软件">
            <product-select :text="form.productName" @change="form.product = $event.licenseCode,form.productName = $event.name" />
          </el-form-item>
          <el-form-item prop="content" label="反馈内容">
            <el-input type="textarea" :rows="4" v-model="form.content" placeholder="请描述您的问题或者建议" maxlength="500"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">取消</el-button>
          <el-button type="primary" @click="handleSave" size="mini">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api/guestbook'
import productSelect from '@/components/productSelect.vue'
export default {
  name: '',
  components: { productSelect },
  data() {
    return {
      dialogVisible: false,
      form: {
        name: '游客',
        phone: '',
        product: '',
        productName: '',
        content: ''
      },
      contentStr: '',
      rules: {
        name: [{ required: true, message: '请输入称呼', trigger: 'change' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'change' }],
        product: [{ required: true, message: '请选择反馈软件', trigger: 'change' }],
        content: [{ required: true, message: '请输入反馈内容', trigger: 'change' }]
      }
    }
  },
  watch: {},
  computed: {
    isPc() {
      return document.body.clientWidth > 800
    }
  },
  created() {},
  methods: {
    handleSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          API.create(this.form).then(res => {
            this.$message({
              message: res.message || '保存成功',
              type: 'success',
              duration: '2000'
            })
            this.dialogVisible = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
/* dialog弹框样式修改 */
.el-dialog__body {
  padding: 0 30px 30px 30px;
}

.el-dialog__header {
  font-size: 22px;
  font-weight: bold;
}

.el-dialog__footer {
  padding-top: 0;
}

@media screen and (max-width: 576px) {
  .el-form-item {
    margin-bottom: 5px !important;
  }

  .el-form-item__label {
    padding-bottom: 0 !important;
  }
}
</style>