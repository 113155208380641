
<template>
  <el-select
    style="width: 100%"
    v-model="innerText"
    filterable
    :clearable="clearable"
    :placeholder="placeholder"
    :disabled="disabled"
    value-key="licenseCode"
    @change="change"
  >
    <el-option v-for="item in softList" :key="item.licenseCode" :label="item.name" :value="item" :disabled="onlyHasNetwork&&!item.apiUrl">
      <div style="display: flex; align-items: center;">
        <img style="width: 20px; height: 20px;" :src="utils.getSoftIcon(item.licenseCode)" alt />
        <span style="margin-left: 10px;" :style="{color:(onlyHasNetwork&&!item.apiUrl)?'#ccc':''}">{{item.name}}</span>
      </div>
    </el-option>
  </el-select>
</template>
  
<script>
import { mapGetters } from 'vuex'
export default {
  name: '',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    onlyHasNetwork: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerText: ''
    }
  },
  watch: {
    text: {
      handler(val) {
        // console.log('watch text', val)
        this.innerText = val
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['softList'])
  },
  created() {
    this.utils.refreshSoftCates()
  },
  methods: {
    change(item) {
      this.$emit('change', item)
    }
  }
}
</script>
  


